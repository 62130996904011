@import "style/index.scss";

.toastui-editor-toolbar {
  height: 1.75rem;
}
.auto-height .toastui-editor-defaultUI {
  margin: -0.25rem 0;
}

.toastui-editor-defaultUI-toolbar {
}

.toastui-editor-main-container {
  text-align: left;
}
.toastui-editor-defaultUI-toolbar button {
  width: 1.25rem;
  height: 1.25rem;
  border: none;
}
.toastui-editor-md-tab-container .tab-item {
  margin-top: 0.5rem;
}
.toastui-editor-defaultUI .ProseMirror {
  padding: 0.75rem 0.9375rem;
  min-height: 4rem;
  height: 100%;
  &[contenteditable="false"] {
    background-color: $gray-200;
  }
}
.toastui-editor-toolbar-group .toastui-editor-toolbar-icons {
  margin: 0;
  width: 1.625rem;
  height: 1.5rem;
}

.toastui-editor-defaultUI .ProseMirror {
  padding: 0.5rem;
}
