@import "style/index.scss";

.phone-select-wrap {
  position: relative;
  display: flex;
  min-width: 5rem;
  column-gap: 0.5rem;
  cursor: pointer;
  border-radius: 0.25rem;

  &[data-one] {
    width: 12.5rem;
    @media (max-width: $md) {
      width: 10rem;
    }
  }

  & > button {
    width: 100%;
    height: 1.875rem;
    padding: 0 0.75rem;
    background-color: #fff;
    border: 0.0625rem solid $gray-400;
    font-size: 0.75rem;
    color: $black-900;
    @include between-center;
    @include transition2;

    &::after {
      @include pseudo("icon-arrow-down.png", 0.75rem, 0.75rem);
      transition: all 0.2s ease-in-out;
    }
    &:disabled {
      background-color: rgba($gray-200, 0.4);
    }

    & > span {
      width: auto;
    }

    &.on::after {
      transform: rotate(-180deg);
    }
  }
}

.option-wrap {
  display: none;
  // width: inherit;
  width: max-content;
  min-height: 12.5rem;
  border-radius: 0.25rem;
  overflow: hidden;

  &[data-name="country"] {
    width: auto;
  }
  @media (max-width: $md) {
    height: 12.5rem;
  }

  ul {
    min-height: 5rem;
    height: inherit;

    background-color: #fff;

    & > li {
      padding: 0rem 0.5rem;
      text-align: left;
      font-size: 0.6875rem;
      cursor: pointer;
      border-bottom: 1px dashed $gray-400;
      @include between-center;

      // &.selected {
      //   background-color: $gray-200;
      // }
      &:hover {
        background-color: $gray-200;
      }
      &[data-selected="true"] {
        background-color: rgba($gray-300, 0.4);
        cursor: no-drop;
      }
      &[data-events="none"] {
        pointer-events: none;
        background-color: rgba($gray-300, 0.4);
        cursor: no-drop;
      }
    }
    span {
      display: inline-block;
      // width: 33%;
      padding: 0.25rem 0.25rem;
    }
    .list-title {
      position: sticky;
      left: 0;
      right: 0;
      top: -0.0625rem;
      z-index: 5;
      // width: auto;
      height: 1.75rem;
      font-size: 0.6875rem;
      background-color: inherit;
      border-bottom: 0.0625rem solid $gray-400;

      &:hover {
        background-color: #fff;
      }
    }

    &.on {
      display: block;
      height: 12.5rem;
      // padding: 0 0.5rem;
      overflow: scroll;
      transition: all 0.2s ease-in-out;
      @include noscroll;
    }
  }

  &.on {
    display: block;
    border: 0.0625rem solid rgba($gray-400, 0.6);
    position: absolute;
    top: 1.75rem;
    left: 0;
    right: 0px;
    z-index: 21;
    width: 100%;
    // min-width: 12.75rem;
    box-sizing: border-box;
    overflow: hidden;
  }
}
