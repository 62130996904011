@import "style/index.scss";

/*******************************************/

.list-wrap {
  position: relative;
  width: 100%;
  margin-top: 1.25rem;
  background-color: #fff;

  .table-title + div {
    // height: 23.125rem;
    overflow-x: scroll;
    overflow-y: hidden;
    border-top: 2px solid $black-400;
    border-bottom: 2px solid $black-400;

    &[data-brd-bt="true"] {
      border-bottom-width: 0px;
    }
  }
  table {
    display: table;
    min-width: 52.5rem;
    border-collapse: collapse;
    background-color: #fff;

    thead {
      tr {
        height: 2.125rem;
        background-color: $gray-200;
      }
    }
    tbody {
      background-color: #fff;
      tr {
        height: 1.875rem;
        border-top: 1px solid $gray-400;
        border-bottom: 1px solid $gray-400;
        background-color: #fff;

        &:hover {
          background-color: rgba($gray-200, 0.4);
        }

        &:last-child {
          border-bottom: 0;
          td {
            border: 0;
          }
          &:hover {
            background-color: #fff;
          }
          &[data-brd] {
            td {
              border: 1px solid $gray-400;
            }
            border-bottom: 2px solid $black-400;
          }
        }
      }
    }

    th,
    td {
      max-width: 12.5rem;
      min-width: 3rem;
      padding: 0 0.5rem;
      height: inherit;
      font-size: 0.75rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      border-right: 1px solid $gray-400;
      word-wrap: break-word;
      word-break: break-all;

      &:first-child {
        width: 3rem;
        border-left: 1px solid $gray-400;
      }
      &:last-child {
        width: 4rem;
        padding: 0 0.25rem;
        &.auto {
          width: auto;
        }
      }
    }

    td {
      text-align: center;
      cursor: pointer;

      button {
        &:disabled {
          cursor: no-drop;
        }
        &.btn-n-use {
          padding: 0.25rem;
        }
        &.red {
          width: 100%;
          height: 100%;
          font-weight: bold;
          color: $red-400;
        }

        &.done {
          @extend .red;
          color: $black-900;
        }

        &.green {
          color: green;
          font-weight: bold;
        }
      }

      span {
        &[data-sts] {
          font-weight: bold;
        }
        &[data-sts="C"] {
          color: $green-300;
        }
        &[data-sts="D"] {
          color: $red-400;
        }
        &[data-sts="U"] {
          color: $black-900;
        }
      }
    }
  }
}

.table-title {
  @include between-center;

  h3 {
    display: flex;
    align-items: flex-end;
    height: 2rem;
    padding: 0 0.75rem;
  }

  h3 + p {
    font-size: 0.75rem;
    font-weight: bold;
  }
  .toggle {
    font-size: 0.75rem;
    text-decoration: underline;
  }
}

.joiAprYn {
  width: inherit;
  height: inherit;
  padding-top: 0.3125rem;
  background-color: rgba($green-300, 0.9);
  color: #fff;
  font-weight: bold;
}

.soYn {
  width: inherit;
  height: inherit;
  background-color: $gray-400;
  color: #fff;
  cursor: no-drop;
  @extend .joiAprYn;
}
