@import "style/index.scss";

header + section {
  display: flex;
  margin-top: var(--header-height);
}

main {
  --main-pd-left: 0px;
  --main-pd-right: 0px;
  width: 100%;
  padding-left: max(var(--main-pd-left));
  padding-right: var(--main-pd-right);
  padding-bottom: 30px;
}

// main {
//   min-width: 50rem;
//   width: calc(100% - 12.5rem);
//   padding: 0.75rem 0.875rem;
//   margin-left: 12.5rem;
//   margin-top: 3.375rem;
//   background-color: white;

//   @media (max-width: $md) {
//     width: calc(100% - 10rem);
//     margin-left: 10rem;
//   }
// }
