@import "style/index.scss";

.error-cont {
  padding: 9.375rem;
  flex-direction: column;
  color: #313338;
  word-wrap: break-word;
  word-break: keep-all;

  @include center;

  & > h1 {
    font-size: 1.75rem;
    margin-top: 1.875rem;
  }

  & > p {
    margin-top: 0.625rem;
    text-align: center;
    font-size: 0.875rem;
    font-weight: 400;
  }

  .err-btn {
    display: flex;
    column-gap: 0.625rem;
    & > button {
      padding: 0.625rem 1.25rem;
      margin-top: 1.25rem;
      font-size: 0.8125rem;
      font-weight: 600;
      color: #313338;
      border-radius: 0.25rem;
      border: 0.0625rem solid $gray-650;
    }
  }
}
