@import "style/index.scss";

.home-wrap {
  // max-width: 55rem;
  width: 100%;
  min-height: calc(100vh - 7.5rem);
  padding: 0.75rem;
  background-color: #fff;
  font-size: 1rem;

  .card {
    min-width: 12.5rem;
    height: 12.5rem;
    padding: 0.75rem;
    border-radius: 0.125rem;
    font-size: 0.75rem;
    border: 1px solid $gray-400;

    & > div {
      height: 1.75rem;
      padding-bottom: 0.75rem;
      margin-bottom: 0.25rem;
      border-bottom: 1px solid $gray-400;
    }
    ul {
      label {
        width: 7.5rem;
      }
      input[type="text"] {
        width: 12.5rem;
        height: 1.875rem;
        border-radius: 0;
      }
    }
    li {
      padding: 0.125rem 0.5rem;
      border-bottom: 1px dashed $gray-400;
      &:last-child {
        border-bottom: 0;
      }
    }
  }
  h4 + button,
  h4 + a {
    font-size: 0.6875rem;
    font-weight: bold;
    text-decoration: underline;
  }

  table {
    width: 100%;
    font-size: 0.75rem;
    border-collapse: collapse;
    border: 1px solid $gray-400;

    th {
      border: 1px solid $gray-400;
      background-color: $gray-200;
    }
    tr {
      height: 1.875rem;

      &:hover {
        background-color: rgba($gray-200, 0.4);
      }
    }
    td {
      flex: 1;
      height: inherit;
      text-align: center;
      border: 1px solid $gray-400;
    }
  }

  &.day-list {
    & > h3 {
      padding: 1.25rem 0;
      border-bottom: 1px solid $black-900;
    }
  }

  .btn-more {
    width: 7.5rem;
    height: 2.125rem;
    padding: 0 1.25rem;
    margin-top: 2.5rem;
    border-radius: 0.5rem;
    border: 0.0625rem solid $gray-500;
    font-size: 0.875rem;
    font-weight: bold;
    @include center;

    &:hover {
      border-color: $black-900;
    }
  }
}

.icon-rotate {
  animation: reload 2s linear infinite; //delay iteration-count direction fill-mode;
}
