@import "./mixin.scss";
@import "./color.scss";

$w-30: 1.875rem;
$w-24: 1.5rem;
$w-20: 1.25rem;
$tbl-max-w: 1200px;
$gbn-w: 12.5rem;

h1 {
  font-size: 1.5rem;
  font-weight: bold;
  @media (max-width: $md) {
    font-size: 1.5rem;
    font-weight: 600;
  }
}

h2 {
  font-size: 1.25rem;
  font-weight: bold;

  @media (max-width: $md) {
    font-size: 1.25rem;
    // font-weight: 600;
  }
}

h3 {
  font-size: 0.9375rem;
  font-weight: bold;

  @media (max-width: $md) {
    font-size: 0.875rem;
    font-weight: 600;
  }
}

h4 {
  font-size: 0.8125rem;
  font-weight: bold;

  @media (max-width: $md) {
    font-size: 0.75rem;
    font-weight: 600;
  }
}

button,
input {
  outline: none;
  border: none;
}

button {
  &:disabled {
    opacity: 0.8;
    cursor: no-drop;
  }

  .fa-plus-square-o {
    font-size: 1.5rem;
  }

  .fa-refresh {
    font-size: 1.5rem;
    border-style: dotted;
    &:hover {
      color: $black-450;
    }
  }
}

a {
  &[role="plus"] {
    &::after {
      @include pseudo("icon_plus_black.jpg", 2rem, 2rem);
    }
  }
}

select {
  width: auto;
  height: 1.875rem;
  border: 1px solid $gray-400;
  &.selected {
    outline: none;
    background-color: $gray-300;
  }
}

.btn {
  min-width: 3.75rem;
  padding: 0.25rem 0.875rem;
  border-radius: 0.25rem;
  font-size: 0.625rem;
  font-weight: 600;
  border: 0.0625rem solid $black-400;

  &[role="b-plus"]::before {
    position: relative;
    left: -1.25rem;
    top: 2px;
    @include pseudo("icon_plus_black.jpg", 0.875rem, 0.875rem);
  }
}

.btn-red {
  width: 15rem;
  height: 3.125rem;
  border-radius: 0.25rem;
  background-color: rgba($red-400, 1);
  font-size: 1rem;
  font-weight: bold;
  color: #fff;

  @media (max-width: $md) {
    width: 5rem;
    height: 2.125rem;
    font-size: 0.875rem;
  }

  &:hover {
    background-color: rgba($red-400, 0.8);
  }

  &:disabled {
    background-color: $gray-300;
    color: $black-900;
    cursor: no-drop;
  }

  &:active {
    // border: 0.0625rem solid $black-900;
    transform: scale(105%);
  }
}

.btn-gray {
  background-color: $gray-300;
  @extend .btn-red;

  &:hover {
    background-color: rgba($gray-300, 0.8);
  }

  &:active {
    border: 0.0625rem solid $black-900;
    transform: scale(101%);
  }
}

.btn-sm {
  min-width: 3rem;
  width: auto;
  height: 1.3125rem;
  padding: 0.125rem 0.25rem;
  font-weight: bold;

  &:disabled {
    background-color: $gray-200;
    color: $black-900;
  }
}

.btn-md {
  min-width: 4rem;
  width: auto;
  height: 1.75rem;
  padding: 0.125rem 0.25rem;
  font-weight: bold;
  background-color: $red-400;
  color: #fff;
  font-size: 0.875rem;
  font-weight: bold;

  &:disabled {
    background-color: $gray-200;
    color: $black-900;
  }

  &.gray {
    background-color: $gray-200;
    color: $black-900;
  }
}

a {
  display: inline-flex;
  text-align: center;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #fff inset;
  -webkit-text-fill-color: #000;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}
input[type="password"],
input[type="text"],
input[type="number"] {
  flex: 1;
  // height: 2.75rem;
  height: 2rem;
  padding-left: 0.5rem;
  border-radius: 0.25rem;
  border: 0.0625rem solid $gray-400;
  font-size: 0.75rem;

  &:disabled,
  &[readonly] {
    cursor: no-drop;
    background-color: rgba($gray-200, 0.4);
  }
  &::placeholder {
    font-size: 0.75rem;
  }
}

input[type="number"] {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}

input[type="radio"],
input[type="checkbox"] {
  display: none;
}

input[type="date"] {
  position: relative;
  min-width: 12.5rem;
  max-width: 25rem;
  width: 100%;
  height: 2.75rem;
  padding-left: 1.25rem;
  background: url("/assets/icon/calendar.png") no-repeat right 0.625rem center /
    1.875rem auto;
  border-radius: 8px;
  text-align: left;
  font-size: 100%;
  border-radius: 0.25rem;
  border: 0.0625rem solid #dfe3e9;

  @media (max-width: $md) {
    font-size: 0.75rem;
  }

  &:read-only {
    background-color: $gray-200;
  }

  &::-webkit-inner-spin-button,
  -webkit-calendar-picker-indicator {
    display: none;
    appearance: none;
  }

  &::-webkit-calendar-picker-indicator {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    color: transparent;
    cursor: pointer;
  }

  &::before {
    content: attr(placeholder);
    width: 100%;
    height: 100%;
  }

  &:valid::before {
    display: none;
  }
}

label {
  display: inline-block;
  cursor: pointer;
  @media (max-width: $md) {
    font-size: 0.75rem;
  }
  &[data-dot] {
    &::before {
      content: "*";
      color: $red-300;
      padding-right: 0.25rem;
    }
  }
}

span {
  display: inline-block;
}

label {
  display: inline-block;
  &[role="form"] {
    width: 12.5rem;
    font-size: 1.125rem;

    @media (max-width: $md) {
      width: 7.5rem;
      min-width: 7.5rem;
      font-size: 0.875rem;
    }

    &::after {
      content: "*";
      color: $red-650;
    }
  }
}

.card {
  max-width: 50rem;
  // width: 100%;
  min-height: 20px;
  // padding: 3rem;
  color: $black-350;
  border-radius: 0.625rem;
  border: 0.1875rem solid $gray-300;
  background-color: #fff;
  overflow: hidden;
  &:hover {
    font-weight: bold;
    color: $black-500;
    border-color: $black-500;
    // @include boxShadow($gray-450);
  }
}

.card-form {
  max-width: 50rem;
  width: 100%;
  min-height: 20px;
  margin: 0 auto;
  padding: 3rem;
  // padding: 5rem;
  // padding: 0;
  border-radius: 0.5rem;
  background-color: #fff;
  column-gap: 1.25rem;
  @include boxShadow($gray-450);
}

.allcheck-wrap {
  width: 100%;
  padding: 1.25rem 0;
  margin: 1.25rem 0;
  border-top: 0.125rem solid $black-400;
  border-bottom: 0.125rem solid $black-400;
}

/**********************   *******************/

.main-cnt {
  min-height: calc(100vh - 4rem);
  height: calc(100% - 4rem);
  padding: 1.25rem 1.25rem 7.5rem 1.25rem;
  // margin: 6.5rem 2.5rem;
  background-color: #fff;

  .sub-title {
    width: fit-content;
    padding: 1.25rem 1.25rem 0;
    border-bottom: 1px solid $gray-300;

    &.dot {
      &::after {
        content: "(필수입력 사항)";
        padding-left: 0.75rem;
        font-size: 0.75rem;
        color: $red-400;
      }
    }
  }
}

.main-title {
  height: 3rem;
  border-bottom: 0.0625rem solid $black-900;
  @include between-center;

  .main-btn-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.5rem;

    a {
      font-weight: bold;
      font-size: 0.625rem;
    }
  }
}

/***************** INFO DETAIL *******************/
.main-layout {
  position: absolute;
  top: 4.375rem;
  bottom: 0;
  left: 12.5rem;
  right: 0;
  width: calc(100% - 12.5rem);
  height: max-content;
  padding: 1.25rem;
  background-color: #fff;
  z-index: 9;

  .title {
    border-bottom: 0.0625rem solid $gray-600;

    a {
      padding: 0.25rem 0.75rem;
      font-size: 0.75rem;
      border-radius: 0.25rem;
      border: 0.0625rem solid $black-900;
      text-align: center;

      &:hover {
        font-weight: bold;
      }
    }
  }
}

/*********** LIST SEARCH FORM *************/
.main-cont {
  min-width: 50rem;
  width: 100%;
  font-size: 0.75rem;
  column-gap: 1.25rem;
  @include between-center;

  &[data-dir-col] {
    display: flex;
    flex-direction: column;
  }
  .sb-ttl {
    width: 100%;
    height: 2.75rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 2px solid $indigo-600;
    background-color: #fff;

    svg {
      &[data-close="true"] {
        width: 2rem;
        font-size: 0.75rem;
        transform: rotate(180deg);
        transition: all 0.2s;
      }
      &[data-close="false"] {
        width: 2rem;
        font-size: 0.75rem;
        transition: all 0.2s;
        // transform: rotate(180deg);
      }
    }
  }
}

.tb-form {
  width: 100%;
  background-color: #fff;

  &[data-t-brd] {
    border-top: 2px solid $black-400;
  }
  &[data-list] {
    li {
      input[type="text"],
      .select-wrap,
      .phone-select-wrap {
        width: 10rem;
        @media (max-width: $md) {
          width: 8.75rem;
        }
      }
    }
  }

  .sb-ttl + div {
    & > ul {
      display: flex;
      // background-color: rgba($gray-200, 0.4);
      &:first-child {
        padding-top: 0.25rem;
      }
      &:last-child {
        padding-bottom: 0.375rem;
        border-bottom: 2px solid $black-400;
        &[data-n-brd] {
          border: none;
        }
      }
      &[data-brd-top] {
        border-top: 2px solid $black-400;
      }
    }
  }
  & > ul {
    display: flex;
    // margin-bottom: 0.125rem;
    // background-color: rgba($gray-200, 0.4);
    &[data-brd-top] {
      border-top: 2px solid $black-400;
    }
    &:first-child {
      padding-top: 0.25rem;
    }
    &:last-child {
      padding-bottom: 0.25rem;
      border-bottom: 2px solid $black-400;

      &[data-n-brd] {
        border: none;
      }
    }
  }

  input[type="file"] + label {
    width: auto;

    &[for="file-img"] {
      position: absolute;
      top: 0;
      left: -0.25rem;
      width: 2rem;
    }
    &[for="file-out"],
    &[for="file-in"] {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 12.5rem;
      height: 12.5rem;
      text-align: center;
      background-color: rgba($gray-200, 0.4);
      border: 1px solid $gray-400;
    }
  }

  label {
    width: 7.5rem;
    height: 1.5rem;
    padding-top: 0.25rem;
    padding-right: 0.75rem;
    font-size: 0.75rem;
    text-align: right;
    font-weight: bold;
    // background-color: rgba($gray-200, 0.4);

    &[data-end] {
      display: flex;
      justify-content: flex-end;
    }

    &[for="phone"] + div {
      .phone-select-wrap,
      .select-wrap {
        width: 5rem;
      }
      .phone-select-wrap + input[type="text"] {
        width: 12.5rem;
        @media (max-width: $md) {
          width: 10rem;
        }
      }
    }
  }

  li {
    display: flex;
    margin-top: 0.125rem;
    justify-content: flex-start;
    align-items: center;

    &[data-end] {
      align-items: flex-end;
    }

    input[type="text"],
    input[type="password"] {
      width: 12.5rem;
      height: 1.875rem;
      font-size: 0.75rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      border-radius: 0;
      border-color: $gray-400;

      &[data-err="true"] {
        border: 1px solid $red-400;
      }
      &[data-err="false"] {
        border: 1px solid $gray-300;
      }

      &[data-mb-1] {
        margin-bottom: 0.125rem;
      }

      &[readonly],
      &:disabled {
        background-color: rgba($gray-200, 0.4);
        border-color: $gray-400;
        cursor: no-drop;
      }

      @media (max-width: $md) {
        width: 10rem;
      }
    }
    .react-datepicker-wrapper {
      input[type="text"] {
        width: 6.25rem;
        height: 2rem;
        cursor: pointer;
      }
    }

    .select-wrap,
    .phone-select-wrap {
      width: 12.5rem;
      @media (max-width: $md) {
        width: 10rem;
      }
    }
    &[data-full] {
      width: 100%;
    }
    &[data-btm-brd] {
      border-bottom: 1px solid $black-400;
    }

    .ops {
      max-width: 87.5rem;
      width: inherit;
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
      border-left: 1px solid $gray-400;
      // background-color: #fff;
    }
  }
  h3 {
    width: 6.25rem;
    padding: 0.125rem 0.75rem;
    background-color: $indigo-400;
    color: white;
    text-align: center;
  }
  h3 + span {
    display: block;
    font-size: 0.75rem;
    font-weight: bold;
    color: $red-400;
  }
}

/****************  IMAGE  UPLOAD  *************************/

input[type="file"] {
  display: none;
}

.img-add {
  width: 12.5rem;
  height: 12.5rem;
  margin-left: 0.25rem;
  // border: 1px solid $black-200;
  background-color: rgba($gray-200, 1);
  font-size: 0.8125rem;

  input[type="file"] + label[for="file-lod"] {
    display: block;
    width: inherit;
    height: inherit;
    &::after {
      width: inherit;
      height: inherit;
      content: attr(data-txt);
      @include center;
    }
  }
}
.img-wrap {
  column-gap: 0.5rem;
  row-gap: 0.5rem;
  flex-wrap: wrap;
  flex: 1;
  @include start-center;
  .img-item {
    position: relative;
    width: 12.5rem;
    height: 12.5rem;
    box-sizing: border-box;

    & > img {
      width: inherit;
      height: inherit;
      border: 1px solid $gray-400;
    }
    & > img + div {
      position: absolute;
      top: -5px;
      right: -5px;
    }
  }
}
