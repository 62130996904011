@import "style/index.scss";

.card-form {
  text-align: center;

  span {
    display: inline-block;
  }
  form {
    position: relative;
    font-size: 0.875rem;
  }
  h2 ~ div {
    position: relative;
  }

  input[type="radio"] {
    & + label {
      font-size: 0.875rem;
      @include center;
      transition: all 0.2s ease;
      &::before {
        display: block;
        content: "";
        width: 0.875rem;
        height: 0.875rem;
        margin-right: 0.25rem;
        border-radius: 0.625rem;
        border: 1px solid $gray-400;
      }
    }
    &:checked + label {
      &::before {
        transition: all 0.2s ease;
        border: 0.25rem solid $black-400;
        // background-color: $gray-650;
      }
    }
  }
}

#id,
#pass {
  width: 100%;
  height: 3.4375rem;
  padding-left: 1.25rem;
  border-radius: 0.25rem;
  border: 0.0625rem solid $gray-300;
}
#id {
  & + label {
    position: absolute;
    top: 1.125rem;
    left: 1.25rem;
    color: $gray-400;
    font-size: 0.875rem;
    pointer-events: none;

    &.on {
      top: 0.25rem;
      font-size: 0.625rem;
    }
  }
  &:focus + label {
    top: 0.25rem;
    font-size: 0.625rem;
    &::after {
      content: " (이메일 입력)";
    }
  }
}
#pass {
  & + label {
    position: absolute;
    top: 1.125rem;
    left: 1.25rem;
    color: $gray-400;
    font-size: 0.875rem;
    pointer-events: none;

    &.on {
      top: 0.25rem;
      font-size: 0.625rem;
    }
  }
  &:focus + label {
    top: 0.25rem;
    font-size: 0.625rem;
    &::after {
      content: " (영문 + 숫자 8자 이상)";
    }
  }
}
.icon-pw {
  position: absolute;
  top: 1.125rem;
  right: 1.25rem;
  cursor: pointer;

  @include pseudo("icon_pwoff.png", 1.5rem, 1.25rem);

  &.on {
    @include pseudo("icon_pwon.png", 1.5rem, 1.25rem);
  }
}

.btn-login {
  margin-top: 5rem;
  width: 100%;
  height: 3.4375rem;
  font-size: 1.125rem;
  @extend .btn-red;
}

.id-remember {
  margin: 1.25rem 0 1.875rem;
  input[type="checkbox"] {
    display: none;
    & + label {
      cursor: pointer;
      @include center;
      &::before {
        padding: 0 0 0 35px;
        line-height: 25px;
        letter-spacing: -0.04em;

        @include pseudo("icon_checkbox_off.png", 1.5rem, 1.5rem);
      }
    }
    &:checked + label::before {
      @include pseudo("icon_checkbox_on.png", 1.5rem, 1.5rem);
    }
  }
}

.member-list {
  width: 100%;
  margin-top: 2.1875rem;
  padding-bottom: 1.875rem;
  column-gap: 1.25rem;
  color: $black-300;
  text-align: center;

  & > a {
    padding-right: 1.375rem;
    text-decoration: underline;

    &:last-child {
      padding-right: 0;
    }
  }
}
