@import "style/index.scss";

.modal-outer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 300;
  &[data-isbckgrd="true"] {
    background: rgba(0, 0, 0, 0.5);
  }
  &[data-isbckgrd="false"] {
    background: rgba(0, 0, 0, 0.2);
  }
}

.modal-wrap {
  display: inline-block;
  min-width: 21.25rem;
  // max-width: 55rem;
  max-height: 90%;
  // min-height: 7.5rem;
  padding: 0.875rem;
  border-radius: 0.25rem;
  background-color: #fff;
  border: 0.0625rem solid $gray-400;
  position: relative;
  top: 50%;
  left: 50%;
  vertical-align: middle;
  transform: translate(-50%, -50%);
  scroll-padding-top: 0;

  .title {
    // border-bottom: 1px solid $black-400;
    @include between-center;

    &[data-fixed] {
      position: fixed;
      // top: 1.125rem;
      left: 0.875rem;
      right: 0.875rem;
      z-index: 1;
      height: 2.5rem;
      background-color: #fff;
    }

    h3 {
      font-size: 1rem;
    }

    .modal-close {
      width: 1.5rem;
      height: 1.5rem;
      @include center;

      &::after {
        @include pseudo("icon_close.png", 1rem, 1rem);
      }
    }
  }
}

.title + div {
  min-height: 3rem;
  font-size: 1rem;
  text-align: center;

  span {
    font-size: 0.9375rem;
    width: -webkit-fill-available;
    // text-align: left;
  }
}

.screen-modal-wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 1.25rem;
  background-color: #fff;
  z-index: 111;
}

.info-outer {
  @extend .modal-outer;
  .info-wrap {
    position: absolute;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    min-height: 37.5rem;
    max-height: 100%;
    background-color: #fff;
    padding: 1.25rem 2.5rem 1.875rem;
    overflow-y: auto;
  }
  .info-title {
    @include between-center;
    border-bottom: 1px solid $gray-400;
  }
}
