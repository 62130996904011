@import "style/index.scss";

.select-wrap {
  position: relative;
  font-size: 0.875rem;

  &[data-small="true"] {
    width: 4rem;
    margin: 0;
    box-sizing: border-box;
    & > button {
      padding: 0 0.25rem;
      height: 1.3125rem;
    }
    & > button + ul {
      width: 4rem;
      min-height: auto;
      height: auto;
      background-color: #fff;
      li {
        padding: 0 0.25rem;
        height: 1.3125rem;
        background-color: #fff;
      }
    }
  }

  & > button {
    width: 100%;
    height: 1.875rem;
    padding: 0 0.5rem;
    font-size: 0.75rem;
    text-align: center;
    line-height: 0.8125rem;
    border: 0.0625rem solid rgba($gray-400, 1);
    background-color: #fff;
    box-sizing: border-box;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    @include between-center;

    &::after {
      display: inline-block;
      margin-left: 0.5rem;
      transition: all 0.2s ease-in-out;
      @include pseudo("icon-arrow-down.png", 0.75rem, 0.75rem);
    }
    &:disabled {
      background-color: rgba($gray-200, 0.4);
    }

    &.on::after {
      transform: rotate(-180deg);

      transition: all 0.2s ease-in-out;
    }
    &[data-isrrow="true"] {
      &::after {
        display: none;
      }
    }
    &[data-isArror="false"] {
      &::after {
        display: flex;
      }
    }
  }

  & > button + ul {
    position: absolute;
    left: 0;
    z-index: 99;
    min-height: 4rem;
    max-height: 12.5rem;
    width: inherit;
    border: 0.0625rem solid $gray-400;
    font-size: 0.875rem;
    overflow: auto;
    background-color: #fff;

    @include noscroll;

    @media (max-width: $md) {
      // width: 8.75rem;
    }

    li {
      height: 1.75rem;
      color: $black-900;
      text-align: left;
      font-size: 0.6875rem;
      padding: 0.25rem 0.75rem;
      word-wrap: break-word;
      word-break: keep-all;
      cursor: pointer;
      background-color: #fff;
      border-bottom: 1px dashed $gray-300;

      &:last-child {
        border-bottom: none;
      }

      &[data-selected="true"] {
        cursor: no-drop;
        background-color: rgba($gray-300, 0.4);
      }
      &[data-events="none"] {
        pointer-events: none;
        background-color: rgba($gray-300, 0.4);
        cursor: no-drop;
      }

      &:hover {
        background-color: rgba($gray-300, 0.4);
      }

      @media (max-width: $md) {
        padding: 0.25rem 0.5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 0.75rem;
      }
    }
  }
}
.search-in-wrap {
  display: flex;
  column-gap: 0.5rem;
  .select-wrap + input[type="text"] {
    height: 1.875rem;
    border: none;
    width: 11.25rem;
    border-radius: 0;
    border: 1px solid rgba($gray-500, 0.4);
  }
}
