@import "style/index.scss";

.radio-wrap {
  display: flex;

  input[type="radio"] {
    display: none;

    & + label {
      min-width: 4rem;
      height: 1.875rem;
      column-gap: 0.75rem;
      margin-right: 0.75rem;
      cursor: pointer;
      @include start-center;

      &::before {
        display: block;
        content: "";
        width: 0.875rem;
        height: 0.875rem;
        border-radius: 50%;
        border: 0.0625rem solid $black-400;
      }
      &.sm {
        width: auto;
        ::before {
          width: 0.75rem;
          height: 0.75rem;
          border: 1px solid $black-400;
        }
      }
      &.square {
        &::before {
          border-radius: 0%;
        }
      }
    }

    &:checked + label {
      &::before {
        content: "";
        width: 0.875rem;
        height: 0.875rem;
        border: 4px solid $black-400;
        @include transition2;
      }
    }

    // &:disabled + label,
    &[readonly] + label {
      cursor: no-drop;
      &::before {
        background-color: $gray-200;
      }
    }
  }
}

.radio-editor {
  margin: 0.25rem 0;
  text-align: left;
  flex: 1;

  input[type="radio"] {
    & + label {
      min-width: 4rem;
      width: auto;
      height: 1.3125rem;
      font-size: 0.6875rem;
      text-align: center;
      background-color: #fff;
      border: 0.0625rem solid $gray-400;
      border-top-right-radius: 0.75rem;
      overflow: hidden;
      transition: all 0.15s linear;
      cursor: pointer;

      &:hover {
        background-color: rgba($gray-300, 0.3);
        transition: all 0.15s linear;
      }

      &::before {
        display: inline-flex;
        content: attr(data-label);
        // clip-path: polygon(0% 0%, 92% 0%, 100% 20%, 100% 100%, 0% 100%);
        width: 100%;
        height: 100%;
        color: $black-900;
        @include center;
      }

      @media (max-width: $md) {
        width: auto;
        height: 2rem;
        font-size: 0.75rem;
        padding: 0.3125rem 0.5rem;
      }
    }

    &:checked {
      & + label {
        border-bottom: 0;
        font-weight: bold;
        background-color: rgba($gray-300, 0.6);
        border-color: $gray-400;
        &::before {
          transition: all 0.1s linear;
        }
      }
    }

    &:disabled,
    &:read-only {
      & + label {
        cursor: no-drop;
      }
    }
  }

  input[type="text"] {
    &[data-full="false"] {
      display: block;
      width: 12.5rem;
    }
    &[data-full="true"] {
      display: block;
      width: 100%;
      margin-top: -0.3125rem;
    }
  }
}
