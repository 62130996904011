@import "style/index.scss";

.react-datepicker-wrapper {
  button {
    width: 7.5rem;
    height: 1.875rem;
    background-color: #fff;
    border: 1px solid $gray-400;
  }
}
