@import "style/index.scss";

.pagenation-wrap {
  display: flex;
  justify-content: center;
  column-gap: 0.25rem;

  ul {
    column-gap: 0.5rem;
    @include center;
  }

  li {
    width: 1.5rem;
    height: 1.125rem;
    border: 0.0625rem solid $gray-400;
    cursor: pointer;
    @include center;

    &:hover {
      background-color: $gray-400;
      color: #fff;
    }
  }

  .curr {
    border: 0.0625rem solid transparent;
    background-color: $black-400;
    color: #fff;
    font-weight: 700;
  }

  .pre-btn,
  .nxt-btn {
    &::after {
      content: "";
      width: 1.5rem;
      height: 1.5rem;
      background: url("/assets/icon/icon_gnb_arrow_black.svg") no-repeat;
      background-size: 0.5rem;
      background-position: 6px 4px;
    }
  }

  .nxt-btn {
    &::after {
      background-position: 7px 7px;
      transform: rotate(-180deg);
    }
  }
}
