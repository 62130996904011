@tailwind base;
@tailwind componentss;
@tailwind utilities;

body {
  margin: 0;
  word-wrap: break-word;
  word-break: keep-all;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --header-height: 54px;
  /* background-color: #eaeaea; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
